import React, { useEffect, useState } from "react";
import SelectSearch from 'react-select-search';
import { useHistory, Link } from "react-router-dom";
import axios from "../../Utils/Axios";
import { useAlert } from "react-alert";
import RingLoader from "react-spinners/RingLoader";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import BLink from '@mui/material/Link';
import LoadingOverlay from "react-loading-overlay-ts";
import IsAllowed from "../../Utils/IsAllowed";
import "./Users.css"
import { ErrorHandler } from "../../ui/ErrorHandler";
function EditUser(props) {
    const alert = useAlert();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [statuses, setStatuses] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [password, setPassword] = useState('');
    const [userRoleId, setUserRoleId] = useState(0);
    const [roles, setRoles] = useState([]);
    const [user, setUser] = useState({});
    const [userId, setUserId] = useState(
        props.match && props.match.params && props.match.params.id
            ? props.match.params.id
            : ""
    );
    useEffect(() => {
        getRoles();
    }, []);
    useEffect(() => {
        getUserDetails();
    }, [userId]);
    const getUserDetails = () => {
        setLoading(true);
        axios("admin/admins/" + userId, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setUser(response.data.data);
                    setUserRoleId(response.data.data.roles[0].id);
                    setLoading(false);
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                    history.push({
                        pathname: "/users",
                        state: { data: true },
                    });
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
                history.push({
                    pathname: "/users",
                    state: { data: true },
                });
            });
    };
    const randomString = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        setPassword(result);
    }
    const getRoles = () => {
        axios("admin/roles", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setRoles(response.data.data);
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };
    const updateUser = () => {
        setLoading(true);
        let formdata = new FormData();
        formdata.append("first_name", user.first_name);
        formdata.append("last_name", user.last_name);
        formdata.append("id", userId);
        formdata.append("email", user.email);
        formdata.append("user_role_id", userRoleId);
        axios(`admin/admin_update`, {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                    history.push({
                        pathname: "/users",
                        state: { data: true },
                    });
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };
    return (
        <div class="container-fluid">

            <div role="presentation">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to="/">
                        Home
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        to="/users"
                    >
                        Users
                    </Link>
                    <Link
                        underline="hover"
                        color="text.primary"
                        to="#"
                        aria-current="page"
                    >
                        Edit Users
                    </Link>
                </Breadcrumbs>
            </div>




            <LoadingOverlay
                active={loading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "rgba(255, 255, 255, 0.9)",
                    }),
                    spinner: (base) => ({
                        ...base,
                        width: "75px",
                        "& svg circle": {
                            stroke: "rgba(0, 0, 0, 0.8)",
                        },
                    }),
                }}
                spinner={<RingLoader loading={loading} color="#36d7b7" />}
                text="..."
            >



                <div class="row">
                    <div class="col-md-12 pl-4 pr-5 mt-3">

                        <h1 className="h3  text-gray-800">Edit Users</h1>
                        <hr></hr>

                        <form method="POST" className=" col-md-6 row m-auto pt-5 p-3 border ">
                            <div class="form-group col-md-6">
                                <label for="name">First Name:</label>
                                <input type="text" id="firstname" name="firstname" onChange={(e) =>
                                    setUser({
                                        ...user,
                                        first_name: e.target.value,
                                    })
                                } value={user.first_name} class="form-control" />
                            </div>
                            <div class="form-group  col-md-6">
                                <label for="name">Last Name:</label>
                                <input type="text" id="lastname" name="lastname" onChange={(e) =>
                                    setUser({
                                        ...user,
                                        last_name: e.target.value,
                                    })
                                } value={user.last_name} class="form-control" />
                            </div>
                            <div class="form-group  col-md-6">
                                <label for="email">Email:</label>
                                <input type="email" id="email" name="email" onChange={(e) =>
                                    setUser({
                                        ...user,
                                        email: e.target.value,
                                    })
                                } value={user.email} class="form-control" />
                            </div>
                            <div class="form-group  col-md-6">
                                <label for="name">Select Role</label>
                                <select className="form-control form-select" onChange={(e) =>
                                    setUserRoleId(e.target.value)
                                } value={userRoleId} >
                                    <option value={0}>Select Role</option>
                                    {roles.map((value, index) => {
                                        return (
                                            <option value={value.id}>{value.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div class="form-group col-md-12 text-center">
                                <button type="button" class="btn btn-primary btn-sm " onClick={updateUser}  >UPDATE</button>
                            </div>
                        </form>
                    </div>
                </div>
            </LoadingOverlay>
        </div>
    )
}
export default EditUser