import React, { useEffect, useState } from "react";
import axios from "../../Utils/Axios";
import RingLoader from "react-spinners/RingLoader";
import LoadingOverlay from "react-loading-overlay-ts";
import { useHistory, Link } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import BLink from '@mui/material/Link';
import { useAlert } from "react-alert";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import "./Users.css";
import IsAllowed from "../../Utils/IsAllowed";
import { ErrorHandler } from "../../ui/ErrorHandler";
function Users() {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [per_page, setPer_page] = useState(10);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const alert = useAlert();

    useEffect(() => {
        getUsers();
    }, [page, per_page]);
    const getUsers = () => {
        let formdata = new FormData();
        formdata.append("page", page);
        formdata.append("per_page", per_page);
        axios("admin/admins", {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setUsers(response.data.data.data);
                    setTotal(response.data.data.last_page);
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };

    return (
        <div>
            <div class="container-fluid">
                <div role="presentation">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/">
                            Home
                        </Link>
                        <Link
                            underline="hover"
                            color="text.primary"
                            to="/users"
                            aria-current="page"
                        >
                            Users
                        </Link>
                    </Breadcrumbs>
                </div>
                <div className="mb-4">
                    <h1 className="h3 mb-2 text-gray-800">Users</h1>
                    <hr></hr>

                    <div class=" mb-4">
                        <div className="col-md-12 text-right pr-5">
                            <Link className="btn btn-primary " to="/add-user">
                                ADD USER
                            </Link>
                        </div>
                        <div class="card-body">
                            <div class="container-fluid">
                                <div>
                                    <div className="table-responsive">
                                        <LoadingOverlay
                                            active={loading}
                                            styles={{
                                                overlay: (base) => ({
                                                    ...base,
                                                    background: "rgba(255, 255, 255, 0.9)",
                                                }),
                                                spinner: (base) => ({
                                                    ...base,
                                                    width: "75px",
                                                    "& svg circle": {
                                                        stroke: "rgba(0, 0, 0, 0.8)",
                                                    },
                                                }),
                                            }}
                                            spinner={<RingLoader loading={loading} color="#36d7b7" />}
                                            text="..."
                                        >
                                            <table className="table table-bordered  ">
                                                <thead>
                                                    <tr>
                                                        <th>No</th>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Created Date</th>
                                                        <th>Action</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {users.length > 0 ? users.map((value, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{value.first_name + ' ' + value.last_name}</td>
                                                                <td>{value.email}</td>
                                                                <td>{value.created_at}</td>
                                                                <td className="space-evenly">
                                                                    <Link to={'/edit-user/' + value.id}><button className="btn btn-sm btn-dark" title="Edit"><i className="fi fi-rr-file-edit"></i></button></Link>
                                                                    {/* <button className="btn btn-sm btn-danger" onClick={() => deleteUser(value.id)} title="Delete"><i className="fa fa-trash"></i></button> */}
                                                                </td>
                                                            </tr>
                                                        );
                                                    }) : (<td colSpan={5} className="text-center"> No Users</td>)}
                                                </tbody>
                                            </table>
                                        </LoadingOverlay>
                                        <div>
                                            <Box p={2} display="flex" justifyContent="center">
                                                <Stack spacing={2}>
                                                    <Pagination
                                                        color="secondary"
                                                        count={total}
                                                        page={page}
                                                        onChange={(event, value) => {
                                                            setPage(value);
                                                        }}
                                                    />
                                                </Stack>
                                                <Stack spacing={2}>
                                                    <Select
                                                        value={per_page}
                                                        onChange={(e) => setPer_page(e.target.value)}
                                                    >
                                                        <MenuItem value={10}>10</MenuItem>
                                                        <MenuItem value={50}>50</MenuItem>
                                                        <MenuItem value={100}>100</MenuItem>
                                                    </Select>
                                                </Stack>
                                            </Box>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Users;
