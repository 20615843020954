import React, { useEffect, useState } from "react";

function EditPageSectionItem({ pageSectionItem, pageSectionItemUpdate, pageSectionItemDelete }) {
    const [pageSectionItemDetail, setPageSectionItemDetail] = useState({ pageSectionItem });
    useEffect(() => {
        setPageSectionItemDetail(pageSectionItem)
    }, [pageSectionItem]);

    return (
        <div className="col-md-12">
            <div>
                <div class="form-group mb-0 formstyle3 mb-2">
                    <input className=" form-control Delete-3 mb-2" type="text" onChange={(e) => setPageSectionItemDetail({
                        ...pageSectionItemDetail,
                        heading: e.target.value,
                    })} value={pageSectionItemDetail.heading}></input>
                    <textarea className="description Delete-4 form-control" onChange={(e) => setPageSectionItemDetail({
                        ...pageSectionItemDetail,
                        content: e.target.value,
                    })} value={pageSectionItemDetail.content} rows={4} cols={40} name="content" />
                </div>
                {/* <div class="form-group ">
                    <label for="sort_order">Sort Order</label>
                    <input class="form-control" type="text" onChange={(e) => setPageSectionItemDetail({
                        ...pageSectionItemDetail,
                        sort_order: e.target.value,
                    })} value={pageSectionItemDetail.sort_order}></input>
                </div> */}
                <div class="form-group Delete-5 ">
                    <img width={100} height={100} src={pageSectionItemDetail.image_url}></img>
                    <label>
                        Upload Image<br></br>
                        <input type="file"
                            onChange={(e) => setPageSectionItemDetail({
                                ...pageSectionItemDetail,
                                image: e.target.files[0],
                            })}
                            className="upload-img" ></input>
                    </label>
                </div>
                <button class="btn btn-primary mr-2" onClick={() => pageSectionItemUpdate(pageSectionItemDetail)}>Update</button>
                <button class="btn btn-danger" onClick={() => pageSectionItemDelete(pageSectionItemDetail.id)}>Delete</button>
            </div>
        </div >
    )
}

export default EditPageSectionItem