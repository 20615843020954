import React, { useEffect, useState } from "react";
import axios from "../../Utils/Axios";
import RingLoader from "react-spinners/RingLoader";
import LoadingOverlay from "react-loading-overlay-ts";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import EditRole from "./EditRole";
import AddRole from "./AddRole";

import IsAllowed from "../../Utils/IsAllowed";
import { ErrorHandler } from "../../ui/ErrorHandler";

import "./Users.css";

function Roles() {
    const alert = useAlert();
    const [loading, setLoading] = useState(false);
    const [roles, setRoles] = useState([]);
    useEffect(() => {
        getRoles();
    }, []);
    const getRoles = () => {
        axios("admin/roles", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setRoles(response.data.data);
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };
    const deleteRole = (id) => {
        setLoading(true);
        let formdata = new FormData();
        formdata.append("id", id);
        axios("admin/role_delete", {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                    getRoles();
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };
    return (
        <div>
            <div class="container-fluid">
                <div role="presentation">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/">
                            Home
                        </Link>
                        <Link
                            underline="hover"
                            color="text.primary"
                            to="/roles"
                            aria-current="page"
                        >
                            Roles
                        </Link>
                    </Breadcrumbs>
                </div>
                <div class="  mb-4">
                    <h1 class="h3 mb-2 text-gray-800">Roles</h1>
                    <div class=" mb-4">
                        <hr></hr>



                        <div className="col-md-12 text-right pr-5">
                            <Link className="btn btn-primary " to="/add-role">
                                ADD ROLE
                            </Link>
                        </div>
                        <div class="card-body">
                            <div class="container-fluid">
                                <div>
                                    <div className="table-responsive">
                                        <LoadingOverlay
                                            active={loading}
                                            styles={{
                                                overlay: (base) => ({
                                                    ...base,
                                                    background: "rgba(255, 255, 255, 0.9)",
                                                }),
                                                spinner: (base) => ({
                                                    ...base,
                                                    width: "75px",
                                                    "& svg circle": {
                                                        stroke: "rgba(0, 0, 0, 0.8)",
                                                    },
                                                }),
                                            }}
                                            spinner={<RingLoader loading={loading} color="#36d7b7" />}
                                            text="..."
                                        >
                                            <table className="table table-bordered  ">
                                                <thead>
                                                    <tr>
                                                        <th>No</th>
                                                        <th>Name</th>
                                                        <th>Created Date</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {roles.length > 0 ? roles.map((value, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{value.name}</td>
                                                                <td>{value.created_at}</td>
                                                                <td className="space-evenly">
                                                                    <Link to={'/edit-role/' + value.id}><button className="btn btn-sm btn-dark" title="Edit"><i className="fi fi-rr-file-edit"></i></button></Link>
                                                                    <button className="btn btn-sm btn-danger" onClick={() => deleteRole(value.id)} title="Delete"><i className="fi fi-rr-trash"></i></button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }) : (<td colSpan={4} className="text-center"> No Roles</td>)}
                                                </tbody>
                                            </table>
                                        </LoadingOverlay>
                                        <div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}
export default Roles;
