import React, { useRef, useEffect } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

const TextEditor = ({ defaultValue, onChange }) => {
    const quillRef = useRef(null);
    const editorStyles = {
        height: '200px', // Fixed height for the editor
        overflow: 'auto', // Enable scrolling
    };
    useEffect(() => {
        const quill = new Quill(quillRef.current, {
            theme: 'snow',
            modules: {
                toolbar: [
                    [{ 'font': [] }, { 'size': [] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ 'color': [] }, { 'background': [] }],
                    [{ 'script': 'sub' }, { 'script': 'super' }],
                    [{ 'header': '1' }, { 'header': '2' }, 'blockquote', 'code-block'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    [{ 'indent': '-1' }, { 'indent': '+1' }],
                    [{ 'direction': 'rtl' }],
                    [{ 'align': [] }],
                    ['link', 'image', 'video'],
                    ['clean']
                ],
                clipboard: {
                    matchVisual: false,
                }
            },
            formats: [
                'font', 'size',
                'bold', 'italic', 'underline', 'strike',
                'color', 'background',
                'script', 'blockquote', 'code-block',
                'header', 'list', 'bullet',
                'indent', 'direction', 'align',
                'link', 'image', 'video'
            ]
        });

        quill.on('text-change', () => {
            onChange(quill.root.innerHTML);
        });

        if (defaultValue) {
            quill.root.innerHTML = defaultValue;
        }

        return () => {
            if (quill && quill.root && quill.root.removeEventListener && quill.destroy) {
                quill.off('text-change');
                quill.destroy();
            }
        };
    }, []);
    return <div style={editorStyles} ref={quillRef} />;
};

export default TextEditor;