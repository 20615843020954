import React, { useEffect, useState } from "react";
import axios from "../../Utils/Axios";
import LoadingOverlay from "react-loading-overlay-ts";
import RingLoader from "react-spinners/RingLoader";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import { ErrorHandler } from "../../ui/ErrorHandler";

import "./PageSection.css";

function PageSection({ page, pageId }) {

    const alert = useAlert();
    const [loading, setLoading] = useState(false);
    const [sections, setSections] = useState([]);
    const [pageSections, setPageSections] = useState([]);
    const [heading, setHeading] = useState('');
    const [content, setContent] = useState('');
    const [image, setImage] = useState('');
    const [section, setSection] = useState(0);
    useEffect(() => {
        getSections();
        getPageSections();
    }, [pageId]);

    const getSections = () => {
        axios(`admin/sections`, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setSections(response.data.data);
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };

    const getPageSections = () => {
        let formData = new FormData();
        formData.append("page", page);
        formData.append("page_id", pageId);
        axios(`admin/get_page_sections`, {
            method: "POST",
            data: formData,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setPageSections(response.data.data);
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };

    const pageSectionAdd = (event) => {
        event.preventDefault();
        setLoading(true);
        let formData = new FormData();
        formData.append("section_id", section);
        formData.append("heading", heading);
        formData.append("content", content);
        formData.append("page", page);
        formData.append("page_id", pageId);
        formData.append("image", image);
        axios("admin/page_section_save", {
            method: "POST",
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message);
                    setSection(0);
                    getPageSections();
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };

    const deletePageSection = (id) => {
        setLoading(true);
        let formData = new FormData();
        formData.append("id", id);

        axios("admin/page_section_delete", {
            method: "POST",
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message);
                    getPageSections();
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };

    return (
        <LoadingOverlay
            active={loading}
            styles={{
                overlay: (base) => ({
                    ...base,
                    background: "rgba(255, 255, 255, 0.9)",
                }),
                spinner: (base) => ({
                    ...base,
                    width: "75px",
                    "& svg circle": {
                        stroke: "rgba(0, 0, 0, 0.8)",
                    },
                }),
            }}
            spinner={<RingLoader loading={loading} color="#36d7b7" />}
            text="..."
        >
            <div className="bg-smoke mb-3">
                <div className="col-md-12">
                    <h4>Choose Theme</h4>
                    <div className="row">
                        {sections.length > 0 ? sections.map((value, index) => {
                            return (
                                <div className="col-md-3 ">
                                    <div className="section-box">
                                        <input type="radio" id={value.value} name="section" onClick={(e) => setSection(value.value)} value={value.value} />
                                        <label for={value.value}> <img src={value.sample_view} className="img-fluid" ></img></label>
                                    </div>
                                </div>
                            )
                        }) : ''}
                    </div>
                </div>
                <div className="col-md-12">
                    <form className="row align-items-end ">
                        {/* <Grid xs={6}>
                                <label for="sections">Sections</label>
                                <SelectSearch options={sections} id="sections" onChange={(e) => setSection(e)} value={section}
                                    name="section_id" search fuzzySearch placeholder="Choose your Section" />
                            </Grid> */}

                        {/* <div className="col-md-6" >
                                <div class="form-group mb-0">
                                    <label for="exampleInputHeading">Common Heading</label>
                                    <input type="text" placeholder="Enter Heading" id="exampleInputHeading" onChange={(e) => setHeading(e.target.value)} value={heading} class="form-control" ></input>
                                </div>  
                                  <div class="form-group">
                                <label>
                                    Upload Image<br></br>
                                    <input type="file" onChange={(e) => setImage(e.target.files[0])} className="upload-img" ></input>
                                </label>
                            </div>  

                            </div> */}
                        <div className="col-md-12 p-3 text-right" >
                            {/* <div class="form-group">
                                <label for="exampleInputContent">Content</label>
                                <textarea className="description" id="exampleInputContent" onChange={(e) => setContent(e.target.value)} value={content} rows={4} cols={40} name="content" />
                            </div> */}
                            <button type="button" class="btn btn-primary" onClick={pageSectionAdd} >Add</button>
                        </div>
                    </form>
                </div>

            </div>
            <div className="row">
                <div className="col-md-12">
                    <table className="table table-bordered  ">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Heading</th>
                                <th>Sample View</th>
                                <th>Sort Order</th>
                                <th>Created At</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>

                            {pageSections.length > 0 ? pageSections.map((value, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{value.heading}</td>
                                        <td><img src={value.section.sample_view} style={{ maxWidth: 500 }}></img></td>
                                        <td>{value.sort_order}</td>
                                        <td>{value.created_at}</td>
                                        <td className="space-evenly">
                                            <Link to={'/edit-page-section/' + value.id}><button className="btn btn-sm btn-dark" title="Edit"><i className="fi fi-rr-file-edit"></i></button></Link>
                                            <button className="btn btn-sm btn-danger" title="Delete" onClick={() => deletePageSection(value.id)}><i className="fi fi-rr-trash"></i></button>
                                        </td>
                                    </tr>
                                );
                            }) : (<td colSpan={5} className="text-center"> No Page Sections</td>)}
                        </tbody>
                    </table>
                </div>
            </div>
        </LoadingOverlay>
    )
}

export default PageSection