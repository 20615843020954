import React, { useEffect, useState } from "react";
import SelectSearch from 'react-select-search';
import { useHistory, Link } from "react-router-dom";
import axios from "../../Utils/Axios";
import { useAlert } from "react-alert";
import RingLoader from "react-spinners/RingLoader";
import LoadingOverlay from "react-loading-overlay-ts";
import IsAllowed from "../../Utils/IsAllowed";
import "./Users.css";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import BLink from '@mui/material/Link';
import { ErrorHandler } from "../../ui/ErrorHandler";
function AddUser() {
    const alert = useAlert();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('')
    const [userRoleId, setUserRoleId] = useState(0);
    const [roles, setRoles] = useState([]);
    useEffect(() => {
        getRoles();
    }, []);
    const getRoles = () => {
        axios("admin/roles", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setRoles(response.data.data);
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };
    const addUser = () => {
        setLoading(true);
        let formdata = new FormData();
        formdata.append("first_name", firstName);
        formdata.append("last_name", lastName);
        formdata.append("email", email);
        formdata.append("password", password);
        formdata.append("password_confirmation", confirmPassword);
        formdata.append("user_role_id", userRoleId);
        axios(`admin/admin_save`, {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                    history.push({
                        pathname: "/users",
                        state: { data: true },
                    });
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };
    return (
        <div class="container-fluid">

            <div role="presentation">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to="/">
                        Home
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        to="/users"
                    >
                        Users
                    </Link>
                    <Link underline="hover" color="text.primary" to="#" aria-current="page">
                        Add User
                    </Link>
                </Breadcrumbs>
            </div>

            <LoadingOverlay active={loading} styles={{
                overlay: (base) => ({
                    ...base,
                    background: "rgba(255, 255, 255, 0.9)",
                }),
                spinner: (base) => ({
                    ...base,
                    width: "75px",
                    "& svg circle": {
                        stroke: "rgba(0, 0, 0, 0.8)",
                    },
                }),
            }} spinner={<RingLoader loading={loading} color="#36d7b7" />}
                text="..."
            >




                <div class="row">

                    <div class="col-md-12 pl-4 pr-5 mt-3">

                        <h1 className="h3  text-gray-800">Add User</h1>
                        <hr>
                        </hr>



                        <div class="col-md-12 pl-4 pr-5">
                            <form method="POST" className=" col-md-6 row m-auto pt-5 p-3 border ">
                                <div class="form-group col-md-6">
                                    <label for="name">First Name:</label>
                                    <input type="text" id="firstname" name="firstname" onChange={(e) => setFirstName(e.target.value)}
                                        value={firstName} class="form-control" />
                                </div>
                                <div class="form-group  col-md-6">
                                    <label for="name">Last Name:</label>
                                    <input type="text" id="lastname" name="lastname" onChange={(e) => setLastName(e.target.value)}
                                        value={lastName} class="form-control" />
                                </div>
                                <div class="form-group  col-md-6">
                                    <label for="email">Email:</label>
                                    <input type="email" id="email" name="email" onChange={(e) => setEmail(e.target.value)}
                                        value={email} class="form-control" />
                                </div>
                                <div class="form-group  col-md-6">
                                    <label for="password">Password</label>
                                    <input type="password" id="password" name="password" onChange={(e) =>
                                        setPassword(e.target.value)} value={password} class="form-control" />
                                </div>
                                <div class="form-group  col-md-6">
                                    <label for="confirm_password">Confirm Password:</label>
                                    <input type="password" id="confirm_password" name="confirm_password" onChange={(e) =>
                                        setConfirmPassword(e.target.value)} value={confirmPassword} class="form-control" />
                                </div>
                                <div class="form-group col-md-6">
                                    <label  >User Role</label>
                                    <select className="form-control form-select" onChange={(e) =>
                                        setUserRoleId(e.target.value)
                                    } value={userRoleId} >
                                        <option value={0}>Select Role</option>
                                        {roles.map((value, index) => {
                                            return (
                                                <option value={value.id}>{value.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div class="form-group col-md-12 text-center">
                                    <button type="button" class="btn btn-primary btn-sm " onClick={addUser}>ADD</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        </div>

    )
}
export default AddUser