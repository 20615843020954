import React, { useEffect, useState } from "react";
import axios from "../../Utils/Axios";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import { ErrorHandler } from "../../ui/ErrorHandler";

import "./Dashboard.css";
function Dashboard() {
  const [dashboard, setDashboard] = useState({});
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  useEffect(() => {
    getDashboard();
  }, []);

  const getDashboard = () => {
    axios("admin/get_dashboard", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setDashboard(response.data.data);
        } else {
          setLoading(false);
          ErrorHandler(alert, response.status, response.data.message);
        }
      })
      .catch(({ response }) => {
        setLoading(false);
        ErrorHandler(alert, response.status, response.data.message);
      });
  };
  // const getDashboard = () => {
  //   axios(`Leads/dashboard`, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/x-www-form-urlencoded",
  //       Authorization: JSON.parse(localStorage.getItem("data")),
  //     },
  //   })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setDashboard(res.data);
  //       } else {
  //         alert.show(res.error);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };

  return (
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-4 col-md-4 mb-4">
          <h1>Welcome !!</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-4 col-md-4 mb-4">
          <div class="card card-1  h-100 py-2">
            <div class="card-body">
              <Link to={{ pathname: `/enquiries`, tab: 1 }}>
                <div class="row no-gutters align-items-center">
                  <div class="col-auto icon-bg">
                    <i class="fa fa-cart-plus fa-2x "> </i>
                  </div>
                  <div class="col mr-2">
                    <div class="text-md font-weight-bold text-white text-uppercase mb-1 ml-2">
                      Pending Leads
                    </div>
                    <div class="h5 mb-0 font-weight-bold text-white mb-1 ml-2">
                      {dashboard && dashboard.pending}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div class="col-xl-4 col-md-4 mb-4">
          <div class="card card-3  h-100 py-2">
            <div class="card-body">
              <Link to={{ pathname: `/enquiries`, tab: 0 }}>
                <div class="row no-gutters align-items-center">
                  <div class="col-auto icon-bg">
                    <i class="fa fa-paper-plane fa-2x"></i>
                  </div>
                  <div class="col mr-2">
                    <div class="text-md font-weight-bold text-white text-uppercase mb-1 ml-2">
                      All Leads
                    </div>
                    <div class="h5 mb-0 font-weight-bold text-white mb-1 ml-2">
                      {dashboard && dashboard.all}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div class="col-xl-4 col-md-6 mb-4">
          <div class="card card-2  h-100 py-2">
            <div class="card-body">
              <Link to={{ pathname: `/enquiries`, tab: 4 }}>
                <div class="row no-gutters align-items-center">
                  <div class="col-auto icon-bg">
                    <i class="fa fa-truck fa-2x"></i>
                  </div>
                  <div class="col mr-2">
                    <div class="text-md font-weight-bold text-white text-uppercase mb-1 ml-2">
                      Completed Leads
                    </div>
                    <div class="h5 mb-0 font-weight-bold text-white mb-1 ml-2">
                      {dashboard && dashboard.completed}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div class="col-xl-4 col-md-6 mb-4">
          <div class="card card-4  h-100 py-2">
            <div class="card-body">
              <Link to={{ pathname: `/enquiries`, tab: 4 }}>
                <div class="row no-gutters align-items-center">
                  <div class="col-auto icon-bg">
                    <i class="fa fa-truck fa-2x"></i>
                  </div>
                  <div class="col mr-2">
                    <div class="text-md font-weight-bold text-white text-uppercase mb-1 ml-2">
                      Cancelled Leads
                    </div>
                    <div class="h5 mb-0 font-weight-bold text-white mb-1 ml-2">
                      {dashboard && dashboard.cancelled}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-6 mb-4">
          <div class="card card-3  h-100 py-2">
            <div class="card-body">
              <Link to={{ pathname: `/enquiries`, tab: 4 }}>
                <div class="row no-gutters align-items-center">
                  <div class="col-auto icon-bg">
                    <i class="fa fa-truck fa-2x"></i>
                  </div>
                  <div class="col mr-2">
                    <div class="text-md font-weight-bold text-white text-uppercase mb-1 ml-2">
                      Processed Leads
                    </div>
                    <div class="h5 mb-0 font-weight-bold text-white mb-1 ml-2">
                      {dashboard && dashboard.processed}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div class="col-xl-4 col-md-6 mb-4">
          <div class="card card-2  h-100 py-2">
            <div class="card-body">
              <Link to={{ pathname: `/enquiries`, tab: 4 }}>
                <div class="row no-gutters align-items-center">
                  <div class="col-auto icon-bg">
                    <i class="fa fa-truck fa-2x"></i>
                  </div>
                  <div class="col mr-2">
                    <div class="text-md font-weight-bold text-white text-uppercase mb-1 ml-2">
                      Rejected Leads
                    </div>
                    <div class="h5 mb-0 font-weight-bold text-white mb-1 ml-2">
                      {dashboard && dashboard.rejected}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div class="col-xl-4 col-md-6 mb-4">
          <div class="card card-4  h-100 py-2">
            <div class="card-body">
              <Link to={{ pathname: `/enquiries`, tab: 4 }}>
                <div class="row no-gutters align-items-center">
                  <div class="col-auto icon-bg">
                    <i class="fa fa-truck fa-2x"></i>
                  </div>
                  <div class="col mr-2">
                    <div class="text-md font-weight-bold text-white text-uppercase mb-1 ml-2">
                      Irrelevant Leads
                    </div>
                    <div class="h5 mb-0 font-weight-bold text-white mb-1 ml-2">
                      {dashboard && dashboard.irrelevant_lead}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div class="col-xl-4 col-md-6 mb-4">
          <div class="card card-3  h-100 py-2">
            <div class="card-body">
              <Link to={{ pathname: `/enquiries`, tab: 4 }}>
                <div class="row no-gutters align-items-center">
                  <div class="col-auto icon-bg">
                    <i class="fa fa-truck fa-2x"></i>
                  </div>
                  <div class="col mr-2">
                    <div class="text-md font-weight-bold text-white text-uppercase mb-1 ml-2">
                      Reminder Leads
                    </div>
                    <div class="h5 mb-0 font-weight-bold text-white mb-1 ml-2">
                      {dashboard && dashboard.reminder}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div class="col-xl-4 col-md-6 mb-4">
          <div class="card card-2  h-100 py-2">
            <div class="card-body">
              <Link to={{ pathname: `/enquiries`, tab: 4 }}>
                <div class="row no-gutters align-items-center">
                  <div class="col-auto icon-bg">
                    <i class="fa fa-truck fa-2x"></i>
                  </div>
                  <div class="col mr-2">
                    <div class="text-md font-weight-bold text-white text-uppercase mb-1 ml-2">
                      Not Qualified Leads
                    </div>
                    <div class="h5 mb-0 font-weight-bold text-white mb-1 ml-2">
                      {dashboard && dashboard.not_qualified}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div class="col-xl-4 col-md-6 mb-4">
          <div class="card card-1  h-100 py-2">
            <div class="card-body">
              <Link to={{ pathname: `/enquiries`, tab: 4 }}>
                <div class="row no-gutters align-items-center">
                  <div class="col-auto icon-bg">
                    <i class="fa fa-truck fa-2x"></i>
                  </div>
                  <div class="col mr-2">
                    <div class="text-md font-weight-bold text-white text-uppercase mb-1 ml-2">
                      Proposal Shared Leads
                    </div>
                    <div class="h5 mb-0 font-weight-bold text-white mb-1 ml-2">
                      {dashboard && dashboard.proposal_shared}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
