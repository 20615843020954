import React, { useEffect, useState } from "react";
import axios from "../../Utils/Axios";
import LoadingOverlay from "react-loading-overlay-ts";
import { useHistory } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import BLink from '@mui/material/Link';
import { useAlert } from "react-alert";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { Link } from "react-router-dom";
import { ErrorHandler } from "../../ui/ErrorHandler";
import "./Catalog.css";
import TextEditor from "../../ui/TextEditor";
function EditCategory(props) {
    const alert = useAlert();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [category, setCategory] = useState({});
    const [seo, setSeo] = useState({});
    const [categoryId, setCategoryId] = useState(
        props.match && props.match.params && props.match.params.id
            ? props.match.params.id
            : ""
    );
    useEffect(() => {
        getCategoryDetails();
    }, [props.match.params.id]);
    const getCategoryDetails = () => {
        axios(`admin/categories/${props.match.params.id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setCategory(response.data.data.category);
                    setSeo(response.data.data.seo[0] ?? {});
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        let formdata = new FormData();
        formdata.append("name", category.name);
        formdata.append("name_ar", category.name_ar);
        formdata.append("description", category.description);
        formdata.append("description_ar", category.description_ar);
        formdata.append("short_description", category.short_description);
        formdata.append("slug", category.slug);
        formdata.append("short_description_ar", category.short_description_ar);
        formdata.append("meta_title", seo.meta_title);
        formdata.append("meta_description", seo.meta_description);
        formdata.append("meta_keyword", seo.meta_keyword);
        formdata.append("store_id", 1);
        formdata.append("lang_id", 1);
        formdata.append("id", categoryId);
        axios("admin/category_update", {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                    history.push({
                        pathname: "/categories",
                        state: { data: true },
                    });
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };
    return (
        <div>
            <div class="container-fluid">
                <div role="presentation">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/">
                            Home
                        </Link>
                        <Link
                            underline="hover"
                            color="inherit"
                            to="/categories"
                        >
                            Categories
                        </Link>
                        <Link
                            underline="hover"
                            color="text.primary"
                            to="#"
                            aria-current="page"
                        >
                            Edit Categories
                        </Link>
                    </Breadcrumbs>
                    <hr></hr>
                </div>
                <div class="container-fluid  mt-5">

                    <div className="row">
                        <div className="col-md-8">
                            {Object.keys(category).length != 0 && (
                                <form method="POST" className="category-form">
                                    <h3 className="edit-title">Edit Category <i class="fi fi-rr-file-edit"></i></h3>
                                    <hr></hr>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField id="outlined-basic" fullWidth label="Name" variant="outlined" onChange={(e) => setCategory({
                                                ...category,
                                                name: e.target.value,
                                            })} value={category.name} name="name" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField id="outlined-basic" fullWidth label="Name Arabic" variant="outlined" onChange={(e) => setCategory({
                                                ...category,
                                                name_ar: e.target.value,
                                            })} value={category.name_ar} name="name_ar" />
                                        </Grid>
                                        <Grid item xs={6}>

                                            <label className="label-description">
                                                Description<br></br>
                                                <TextEditor defaultValue={category.description} onChange={(e) => setCategory({
                                                    ...category,
                                                    description: e,
                                                })} />
                                            </label>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <label className="label-description">
                                                Description Arabic<br></br>
                                                <TextEditor defaultValue={category.description_ar} onChange={(e) => setCategory({
                                                    ...category,
                                                    description_ar: e,
                                                })} />
                                            </label>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <label className="label-description">
                                                Short Description<br></br>
                                                <textarea className="description" rows={4} cols={40} onChange={(e) => setCategory({
                                                    ...category,
                                                    short_description: e.target.value,
                                                })} value={category.short_description} name="short_description" />
                                            </label>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <label className="label-description">
                                                Short Description Arabic<br></br>
                                                <textarea className="description" rows={4} cols={40} onChange={(e) => setCategory({
                                                    ...category,
                                                    short_description_ar: e.target.value,
                                                })} value={category.short_description_ar} name="short_description_ar" />
                                            </label>
                                        </Grid>
                                        <Grid item xs={6} >
                                            <lable>
                                                Upload Image<br></br>
                                                <input type="file" className="upload-img" ></input>
                                            </lable>
                                        </Grid>
                                        <Grid item xs={6} >
                                            <lable>
                                                Upload Image Arabic<br></br>
                                                <input type="file" className="upload-img" ></input>
                                            </lable>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <button type="button" onClick={handleSubmit} className="btn btn-primary  "><i class="fi fi-rr-refresh"></i> Update</button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}

                        </div>
                        <div className="col-md-4">
                            {Object.keys(seo).length != 0 && (
                                <form method="POST" className="category-form">
                                    <h3 className="edit-title"> SEO Contents  <i class="fi fi-rr-file-edit"></i> </h3>
                                    <hr></hr>

                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField id="outlined-basic" fullWidth label="Seo url" variant="outlined" onChange={(e) => setCategory({
                                                ...category,
                                                slug: e.target.value,
                                            })} value={category.slug} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField id="outlined-basic" fullWidth label="Meta Title" variant="outlined" onChange={(e) => setSeo({
                                                ...seo,
                                                meta_title: e.target.value,
                                            })} value={seo.meta_title} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <label className="label-description">
                                                Meta Description<br></br>
                                                <textarea className="description" rows={4} cols={40} onChange={(e) => setSeo({
                                                    ...seo,
                                                    meta_description: e.target.value,
                                                })} value={seo.meta_description} />
                                            </label>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField id="outlined-basic" fullWidth label="Meta Keyword" variant="outlined" onChange={(e) => setSeo({
                                                ...seo,
                                                meta_keyword: e.target.value,
                                            })} value={seo.meta_keyword} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            {/* <button type="button" onClick={handleSubmit} className="btn btn-primary  "><i class="fi fi-rr-refresh"></i> Update</button> */}
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}
export default EditCategory;