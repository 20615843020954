import React, { useState, useEffect } from "react";
import axios from "../../Utils/Axios";
import { useAlert } from "react-alert";
import RingLoader from "react-spinners/RingLoader";
import LoadingOverlay from "react-loading-overlay-ts";
import { useHistory, Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { ErrorHandler } from "../../ui/ErrorHandler";

import 'react-dropdown-tree-select/dist/styles.css'
import "./Users.css"

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}
function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}
function AddRole() {
  const alert = useAlert();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };
  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };
  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };
  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };
  const getPermissions = () => {
    axios("admin/permissions", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setRight(response.data.data);
        } else {
          setLoading(false);
          ErrorHandler(alert, response.status, response.data.message)
        }
      })
      .catch(({ response }) => {
        setLoading(false);
        ErrorHandler(alert, response.status, response.data.message)
      });
  };
  const handleSubmit = (event) => {

    event.preventDefault();
    setLoading(true);
    let formdata = new FormData();
    formdata.append("name", name);
    formdata.append("permissions", left.map(item => item.id).join(','));
    axios("admin/role_save", {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          ErrorHandler(alert, response.status, response.data.message)
          history.push({
            pathname: "/roles",
            state: { data: true },
          });
        } else {
          setLoading(false);
          ErrorHandler(alert, response.status, response.data.message)
        }
      })
      .catch(({ response }) => {
        setLoading(false);
        ErrorHandler(alert, response.status, response.data.message)
      });
  };
  useEffect(() => {
    getPermissions();
  }, []);
  const customList = (items) => (
    <Paper sx={{ width: 200, height: 230, overflow: 'auto' }}>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value.id}-label`;
          return (
            <ListItemButton
              key={value}
              role="listitem"
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.name} />
            </ListItemButton>
          );
        })}
      </List>
    </Paper>
  );
  return (
    <div class="container-fluid">


      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" to="/">
          Home
        </Link>
        <Link
          underline="hover"
          color="inherit"
          to="/roles"
        >
          Roles
        </Link>
        <Link
          underline="hover"
          color="text.primary"
          to="#"
          aria-current="page"
        >
          Add Roles
        </Link>
      </Breadcrumbs>

      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 0.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "rgba(0, 0, 0, 0.8)",
            },
          }),
        }}
        spinner={<RingLoader loading={loading} color="#36d7b7" />}
        text="..."
      >
        <div className="col-md-12 mb-5">
          <h1 className="h3  text-gray-800">Add Role</h1>
          <hr></hr>
        </div>


        <form method="POST">
          <div class="form-group rolename">
            <label for="name">Name:</label>
            <input type="text" id="name" onChange={(e) => setName(e.target.value)} value={name} name="name" className="form-control" />
          </div>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item className="border col-md-4"> <h4 className="role-permission-title">Allowed Permission</h4>{customList(left)}</Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleAllRight}
                  disabled={left.length === 0}
                  aria-label="move all right"
                >
                  <i class="fi fi-rr-angle-double-small-right"></i>
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  <i class="fi fi-rr-angle-small-right"></i>
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  <i class="fi fi-rr-angle-small-left"></i>
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleAllLeft}
                  disabled={right.length === 0}
                  aria-label="move all left"
                >
                  <i class="fi fi-rr-angle-double-small-left"></i>
                </Button>
              </Grid>
            </Grid>
            <Grid item className="border col-md-4"> <h4 className="role-permission-title">All Permission</h4> {customList(right)}</Grid>
          </Grid>
          <div class="form-group text-center mt-4">
            <button type="button" onClick={handleSubmit} className="btn btn-primary btn-sm "  >Submit</button>
          </div>
        </form>
      </LoadingOverlay>
    </div>
  )
}
export default AddRole
