export const ErrorHandler = (alert, code, message) => {
    switch (code) {
        case 200: alert.success(message); break;
        case 404: alert.error(message); break;
        case 401: alert.error(message); break;
        case 404: alert.error(message); break;
        case 400: alert.error(message); break;
        case 409: alert.error(message); break;
        case 422: {
            {
                Object.keys(message).map(field => (
                    alert.error(message[field])
                ))
            };
            break;
        }
        case 500: alert.error(message); break;
    }
}