import React, { useEffect, useState } from "react";
import axios from "../../Utils/Axios";
import RingLoader from "react-spinners/RingLoader";
import LoadingOverlay from "react-loading-overlay-ts";
import { Link } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import BLink from '@mui/material/Link';
import { useAlert } from "react-alert";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { ErrorHandler } from "../../ui/ErrorHandler";
import "./Logs.css";
function Log() {
    const [loading, setLoading] = useState(false);
    const [logs, setlogs] = useState([]);
    const [per_page, setPer_page] = useState(10);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const alert = useAlert();
    useEffect(() => {
        getlogs();
    }, [page, per_page]);
    const getlogs = () => {
        setLoading(true);
        let formdata = new FormData();
        formdata.append("page", page);
        formdata.append("per_page", per_page);
        axios("admin/activity_log", {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    setlogs(response.data.data.data);
                    setTotal(response.data.data.last_page);
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };

    return (
        <div>
            <div class="container-fluid">
                <div role="presentation">
                    <Breadcrumbs aria-label="breadcrumb">
                        <BLink underline="hover" color="inherit" href="/">
                            Home
                        </BLink>
                        <BLink
                            underline="hover"
                            color="text.primary"
                            aria-current="page"
                            href="/log"
                        >
                        Logs
                        </BLink>
                    </Breadcrumbs>
                </div>
                <div class="mb-4">
                    <div class="card-body">
                        <h1 class="h2 mb-2 text-gray-800">Activity Logs</h1>
                        <hr></hr>
                        {/* <div className="col-md-12 text-right">
                            <Link to="./add-service"><button className="btn btn-primary"> <i class="fi fi-rr-rectangle-history-circle-plus"></i> Add logs</button></Link>
                        </div> */}
                        <div class="container-fluid">
                            <div className="mt-2">
                                <div className="table-responsive">
                                    <LoadingOverlay
                                        active={loading}
                                        styles={{
                                            overlay: (base) => ({
                                                ...base,
                                                background: "rgba(255, 255, 255, 0.9)",
                                            }),
                                            spinner: (base) => ({
                                                ...base,
                                                width: "75px",
                                                "& svg circle": {
                                                    stroke: "rgba(0, 0, 0, 0.8)",
                                                },
                                            }),
                                        }}
                                        spinner={<RingLoader loading={loading} color="#36d7b7" />}
                                        text="..."
                                    >
                                        <table className="table table-bordered  ">
                                            <thead>
                                                <tr>
                                                    <th>No</th>
                                                    <th>Name</th>
                                                    <th>Created At</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {logs.length > 0 ? logs.map((value, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{value.description}</td>
                                                            <td>{value.created_at}</td>
                                                            <td>{value.event}
                                                            {value.event === "updated" ? (
 <ul>
  {Object.keys(value.properties.attributes).map((key) => (
   (key !== "updated_at" && key !== "long_description"  && key !== "short_description") ? (
      <li key={key}>
       {`${key}: `}
        <strong>{value.properties.old[key]} (old)</strong>
        {` Changed  to `}  <strong>{`${value.properties.attributes[key]} (new)`}</strong>
      </li>
    ) : (
        (key === "short_description" ||  key === "long_description") ? (
            // JSX code for else if condition
            <li key={key}>
                {`${key}: `}
                <strong>{` description updated`}</strong>
            </li>
        ) : null
    )



    
  ))}
</ul>
  ) : null}
                                                            </td>
                                                            
                                                        </tr>
                                                    );
                                                }) : (<td colSpan={4} className="text-center"> No logs</td>)}
                                            </tbody>
                                        </table>
                                    </LoadingOverlay>
                                    <div>
                                        <Box p={2} display="flex" justifyContent="center">
                                            <Stack spacing={2}>
                                                <Pagination
                                                    color="secondary"
                                                    count={total}
                                                    page={page}
                                                    onChange={(event, value) => {
                                                        setPage(value);
                                                    }}
                                                />
                                            </Stack>
                                            <Stack spacing={2}>
                                                <Select
                                                    value={per_page}
                                                    onChange={(e) => setPer_page(e.target.value)}
                                                >
                                                    <MenuItem value={10}>10</MenuItem>
                                                    <MenuItem value={50}>50</MenuItem>
                                                    <MenuItem value={100}>100</MenuItem>
                                                </Select>
                                            </Stack>
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}
export default Log;
