import { useContext, useEffect } from "react";
import { AuthContext } from "../Context/AuthContext";
//import axios from "../Utils/axios";

const IsAllowed = (permission) => {
  const { permissions, role } = useContext(AuthContext);
  return role == "Super Admin"
    ? true
    : (permissions || []).find((p) => p.name === permission)
    ? true
    : false;
};
export default IsAllowed;

//export const hasRole = (user, roles) =>
// roles.some(role => user.roles.includes(role));
