import React, { useEffect, useState } from "react";

function EditFaq({ faq, faqUpdate, faqDelete }) {
    const [faqDetail, setFaqDetail] = useState({ faq });
    useEffect(() => {
        setFaqDetail(faq)
    }, [faq]);

    return (
        <div className="col-md-12">
            <hr className="mt-0" />
            <div className="row align-items-end">
                <div class="form-group col-md-5 mb-0">
                    <label for="exampleInputQuestion">Question</label>
                    <textarea class="form-control" onChange={(e) => setFaqDetail({
                        ...faqDetail,
                        question: e.target.value,
                    })} value={faqDetail.question}></textarea>
                </div>
                <div class="form-group col-md-5  mb-0">
                    <label for="exampleInputAnswer">Answer</label>
                    <textarea class="form-control" onChange={(e) => setFaqDetail({
                        ...faqDetail,
                        answer: e.target.value,
                    })} value={faqDetail.answer}></textarea>
                </div>
                <div className="col-md-2 d-flex">
                    <button class="btn btn-primary mr-2" onClick={() => faqUpdate(faqDetail)}>Update</button>
                    <button class="btn btn-danger" onClick={() => faqDelete(faqDetail.id)}><i className="fa fa-trash"></i></button>
                </div>
            </div>
        </div>
    )
}

export default EditFaq